import { CommonModule } from '@angular/common';
import { Component, model, OnDestroy, signal } from '@angular/core';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import moment from 'moment';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'claim-reward',
  standalone: true,
  imports: [CommonModule, TranslatePipe, IconComponent],
  template: `
    @if(isRewardAvailable()) {
    <div class="flex flex-col justify-center items-center gap-[30px]">
      <img
        src="/icons/chest-close.webp"
        class="h-auto w-[175px]"
        alt="daily-reward"
      />

      <p class="text-white font-DigitalSans uppercase text-[16px]">
        {{ 'DAILY_REWARD' | translate }}
      </p>

      <p class="text-white font-DigitalSans text-[12px]">
        {{ 'COME_BACK_EVERY_DAY_TO_COLLECT_THE_DOUBLE_EXP_BONUS' | translate }}
      </p>

      <button
        (click)="onClaim()"
        class="bg-primary-550 hover:bg-[#93bb4a] text-black font-semibold py-1 px-12 rounded-full text-[14px] transition-colors"
      >
        {{ 'CLAIM_NOW' | translate }}
      </button>
    </div>
    } @else {
    <div class="flex flex-col justify-center items-center gap-[20px]">
      <img
        src="/icons/chest-open.webp"
        class="h-[175px] w-[175px]"
        alt="daily-claimed"
      />

      <p
        class="text-primary-600 font-DigitalSans text-[24px] flex items-center gap-2"
      >
        +DOUBLE
        <span class="rounded bg-gray-500 text-white px-[4px] text-[14px]"
          >EXP</span
        >
      </p>

      <div class="flex flex-col justify-center items-center gap-2">
        <p>{{ 'DOUBLE_EXP_WILL_EXPIRE_IN' | translate }}</p>
        <div class="flex gap-2">
          <div class="flex flex-col justify-center items-center">
            <div class="flex gap-2">
              <p class="{{ digitalClass }}">0</p>
              <p class="{{ digitalClass }}">0</p>
            </div>
            <p class="mt-2">{{ 'HOUR' | translate }}</p>
          </div>
          <p
            class="font-DigitalNumber px-[5px] py-[10px] rounded-[5px] text-[32px]"
          >
            :
          </p>
          <div class="flex flex-col justify-center items-center">
            <div class="flex gap-2">
              <p class="{{ digitalClass }}">0</p>
              <p class="{{ digitalClass }}">0</p>
            </div>
            <p class="mt-2">{{ 'MIN' | translate }}</p>
          </div>
          <p
            class="font-DigitalNumber px-[5px] py-[10px] rounded-[5px] text-[32px]"
          >
            :
          </p>
          <div class="flex flex-col justify-center items-center">
            <div class="flex gap-2">
              <p class="{{ digitalClass }}">0</p>
              <p class="{{ digitalClass }}">0</p>
            </div>
            <p class="mt-2">{{ 'SEC' | translate }}</p>
          </div>
        </div>
      </div>

      <button
        (click)="onClaim()"
        class="bg-gray-500 text-black font-semibold py-1 px-12 rounded-full text-[14px] text-white"
        [disabled]="true"
      >
        {{ 'ALREADY_CLAIMED' | translate }}
      </button>

      <p class="font-DigitalSans text-[18px] flex items-center gap-2">
        {{ 'CLAIM_REWARD_AGAIN_IN' | translate }} :
        <span class="flex gap-2 items-center flex-nowrap text-primary-550">
          <core-icon
            path="/icons/clock.svg"
            class="!w-[18px] !h-[18px] bg-primary-550"
          />

          {{ countDownClaimAgain().hour }} :{{
            countDownClaimAgain().minute
          }}
          :{{ countDownClaimAgain().second }}
        </span>
      </p>
    </div>
    }
  `,
})
export class ClaimRewardComponent implements OnDestroy {
  isRewardAvailable = model<boolean>();

  claimedAt = moment().hour(10).format();

  countDownClaimAgain = signal<{
    hour: number;
    minute: number;
    second: number;
  }>({
    hour: 0,
    minute: 0,
    second: 0,
  });

  timeout!: NodeJS.Timeout;

  digitalClass =
    'font-DigitalNumber text-[32px] px-[5px] py-[10px] bg-gray-500 rounded-[5px]';

  constructor() {
    this.timeout = setInterval(() => {
      const rs = moment.duration(
        moment(this.claimedAt).add(12, 'hours').diff(moment())
      );

      this.countDownClaimAgain.set({
        hour: rs.hours(),
        minute: rs.minutes(),
        second: rs.seconds(),
      });
    }, 1000);
    this.isRewardAvailable.set(true);
  }

  onClaim() {
    this.isRewardAvailable.set(false);
  }

  ngOnDestroy() {
    clearInterval(this.timeout);
  }
}
