import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { PlayerInfo } from '../models/account-center.model';
import { ClientAuthService } from '../services/auth.service';
export const playerInfoGuard: CanActivateFn = async function () {
  const authService = inject(ClientAuthService);

  if (!authService.playerInfo()) {
    authService.getPlayerInfo().subscribe((response: PlayerInfo) => {
      authService.setPlayerInfo(response);
    });
  }

  return true;
};
