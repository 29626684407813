import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { map } from 'rxjs';
import { HomepageMenuProviderModel } from '../../../models/homepage.model';
import { DispatchService } from '../../../services/dispatch.service';
import { HomepageService } from '../../../services/homepage.service';

@Component({
  standalone: true,
  selector: 'app-category',
  templateUrl: './category.component.html',
  imports: [CommonModule, OverlayPanelModule, TranslatePipe],
})
export class CategoryComponent {
  router = inject(Router);
  homepageService = inject(HomepageService);
  dispatchService = inject(DispatchService);
  gameCategories = toSignal(
    this.homepageService.getMetadata().pipe(map((val) => val.menu))
  );

  selectedCategory = signal<HomepageMenuProviderModel[]>([]);

  logo = toSignal(
    this.homepageService.getMetadata().pipe(map((val) => val.merchantLogoImage))
  );

  handleMouseOver(
    e: any,
    op: OverlayPanel,
    childList: HomepageMenuProviderModel[]
  ) {
    this.selectedCategory.set(childList);
    op.show(e);
  }

  handleClick(item: HomepageMenuProviderModel) {
    if (item.isMaintenance) {
      return;
    }

    if (item.redirectType === 'desktop') {
      this.router.navigateByUrl(item.urlPath);
      return;
    }

    if (item.redirectType === 'dispatch') {
      this.dispatchService.openDispatchWindow(item.urlPath);
      return;
    }

    if (item.redirectType === 'iframe') {
      // this.homepageService.goToIframe(item.urlPath);
      return;
    }
  }
}
