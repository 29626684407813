import { afterNextRender, Component, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { filter } from 'rxjs';
import { MarketingPopupService } from '../services/marketing-popup.service';
@Component({
  standalone: true,
  imports: [RouterModule, ToastModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'client';

  router = inject(Router);
  marketingPopupService = inject(MarketingPopupService);

  constructor() {
    afterNextRender(() => {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          this.marketingPopupService.showMarketingPopup(event.url);
        });
    });
  }
}
