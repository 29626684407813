import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-site-info',
  templateUrl: './site-info.component.html',
  imports: [CommonModule],
})
export class SiteInfoComponent {}
