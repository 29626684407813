import { CommonModule } from '@angular/common';
import { afterRender, Component, effect, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateDirective, TranslatePipe } from '@codeandweb/ngx-translate';
import { ClientAuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { IconComponent } from '../icon/icon.component';
import { PasswordComponent } from '../password/password.component';
import { ClientButtonComponent } from '../specify-button/button.component';
import { TextFieldComponent } from '../text-field/text-field.component';
import { CreditBalanceComponent } from './credit-balance/credit-balance.component';
import { DailyRewardComponent } from './daily-reward/daily-reward.component';
import { PlayerInfoComponent } from './player-info/player-info.component';
import { ToggleLanguageComponent } from './toggle-language/toggle-language.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ClientButtonComponent,
    PasswordComponent,
    TextFieldComponent,
    FormsModule,
    TranslatePipe,
    TranslateDirective,
    CreditBalanceComponent,
    ToggleLanguageComponent,
    DailyRewardComponent,
    PlayerInfoComponent,
  ],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  router = inject(Router);

  authService = inject(ClientAuthService);
  storageService = inject(StorageService);
  isLogin = this.authService.isLogin;

  constructor() {
    afterRender(() => {
      const sid = this.storageService.getSid();
      if (sid) {
        this.isLogin.set(true);
      }
    });

    effect(() => {
      if (this.isLogin() === true) {
        this.authService.getUserInfo().subscribe();
      }
    });
  }

  selectedCountry = 'England';
  dropdownBankOption = signal<
    {
      label: string;
      value: string | number;
      disable?: boolean;
      path?: string;
    }[]
  >([
    {
      label: 'England',
      value: 'England',
      path: '/icons/france.svg',
    },
    {
      label: 'Indonesia',
      value: 'Indonesia',
      path: '/assets/search.svg',
    },
  ]);
  pathArray = signal<{ value: string | number; path: string }[]>([
    {
      value: 'England',
      path: '/icons/france.svg',
    },
    {
      value: 'Indonesia',
      path: '/assets/search.svg',
    },
  ]);
  dropdownBankValue = signal<{
    label: string;
    value: string | number;
    disable?: boolean;
  }>({
    label: 'England',
    value: '0',
  });
  user = signal({
    userName: '',
    password: '',
  });
  goToPage(page: string) {
    this.router.navigate([page]);
  }

  login() {
    if (this.user().userName === '' || this.user().password === '') {
      alert('Please fill in the username and password');
      return;
    }
    this.authService
      .login(this.user().userName, this.user().password)
      .subscribe((res) => {
        if (res) {
          this.storageService.setSid(res.data.accessToken);
          this.storageService.setRid(res.data.refreshToken);
          this.storageService.setUid(res.data.userId);
          this.router.navigate(['/']);
        }
      });
  }

  logout() {
    this.authService.logout();
  }
}
