@if(isLogin()) {
<div class="bg-primary-0">
  <div class="flex flex-col justify-center max-w-[1200px] m-auto">
    <div class="flex justify-between">
      <toggle-language />
      <div class="flex gap-2 p-2 ml-auto">
        <div class="flex gap-2 items-center bg-primary-800 px-2 rounded">
          <player-info />
          <credit-balance />
          <daily-reward />
        </div>
        <img
          tabindex="0"
          (click)="goToPage('account-center/my-account')"
          src="/icons/user.svg"
          alt="avt"
          class="w-8 bg-white/90 rounded p-2 hover:cursor-pointer"
        />
        <img
          tabindex="1"
          (click)="goToPage('account-center/bank')"
          src="/icons/bank.svg"
          href="/account-center/bank"
          alt="avt"
          class="w-8 bg-white/90 rounded p-2 hover:cursor-pointer"
        />
        <div
          tabindex="2"
          class="w-8 bg-button-danger rounded p-2 hover:cursor-pointer"
          (click)="logout()"
        >
          <core-icon class="bg-white" path="/icons/logout.svg" />
        </div>
      </div>
    </div>
  </div>
</div>
} @else {
<div class="flex p-4 justify-center max-w-[1200px] m-auto">
  <div class="flex gap-2">
    <toggle-language />
  </div>

  <div class="flex gap-3 ml-auto">
    <core-text-field
      [(ngModel)]="user().userName"
      placeholder="Username"
    ></core-text-field>
    <core-password
      [(ngModel)]="user().password"
      placeholder="Password"
    ></core-password>
    <core-client-button
      class="[&_button]:min-h-[unset]"
      label="Login"
      type="outline"
      (click)="login()"
    />
    <core-client-button
      class="[&_button]:min-h-[unset]"
      (click)="goToPage('register')"
      label="Register"
    />
  </div>
</div>
}
