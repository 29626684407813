<div class="flex w-full items-center">
  <div class="flex w-[50%]">
    <img
      class="w-full h-full max-h-[408px] max-w-[566px] animate-grow"
      alt="Download APK"
      priority
      width="566"
      height="408"
      ngSrc="https://d2rzzcn1jnr24x.cloudfront.net/Images/nexus-alpha/dark-purple/desktop/home/download-apk-phone.png?v=20241103-1"
    />
  </div>
  <div class="flex flex-col w-[50%] h-full justify-between animate-grow">
    <div class="flex flex-col text-4xl font-thin w-full">
      DOWNLOAD FREE APK FROM
      <strong>OVO288 App</strong>
    </div>
    <div class="flex text-2xl font-medium w-full">
      Enjoy multiple games in one hand
    </div>
    <div class="flex w-full my-[10%]">
      <div class="flex flex-col w-[25%] items-center">
        <img
          class="w-full h-full max-h-[94px] max-w-[94px]"
          src="https://d2rzzcn1jnr24x.cloudfront.net/Images/apk-qrcodes/OV2.jpg?v=20241103-1"
          alt="qrcode"
        />
        <span class="font-semibold text-lg text-center"
          >Scan QR code to Download</span
        >
        <span class="text-text-100">Android APK </span>
      </div>
      <div class="flex flex-col w-[25%] justify-around text-center">
        <span class="text-lg font-medium">OVO288 App</span>
        <core-button label="Install Guide"></core-button>
      </div>
    </div>
  </div>
</div>
