import { CommonModule, formatNumber } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { PlayerInfo } from '../../../models/account-center.model';
import { ClientAuthService } from '../../../services/auth.service';

@Component({
  standalone: true,
  selector: 'player-info',
  templateUrl: './player-info.component.html',
  imports: [CommonModule, TooltipModule],
})
export class PlayerInfoComponent {
  authService = inject(ClientAuthService);
  playerInfo = signal<PlayerInfo | null>(null);
  maxLevelScore = signal<number>(25000);
  playerScore = signal<number>(0);
  router = inject(Router);

  constructor() {
    effect(
      () => {
        const playerInfo: PlayerInfo | null = this.authService.playerInfo();
        this.playerInfo.set(playerInfo);
        if (playerInfo?.xpAmount) {
          this.playerScore.set(playerInfo?.xpAmount);
        }
      },
      { allowSignalWrites: true }
    );
  }

  formatNumber(value: number) {
    return formatNumber(value, 'en');
  }

  toMyAccountPage() {
    this.router.navigate(['/account-center/my-account']);
  }
}
