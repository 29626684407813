<div
  class="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-4 divide-x"
>
  <div class="flex flex-col pr-5 gap-3 w-full">
    <div class="flex gap-2">
      <img
        src="/icons/call-service.svg"
        alt="call-service"
        class="max-w-[64px]"
      />
      <div class="flex flex-col">
        <div class="text-text-100 text-2xl">Service</div>
        <div class="text-text-100 text-lg">Service advantage</div>
      </div>
    </div>
    <div class="flex justify-between gap-3">
      <div class="flex flex-col gap-2">
        <div class="text-text-100 text-2xl">Deposit</div>
        <div class="text-text-500 text-lg">Avarage time</div>
        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            class="bg-primary-550 h-2.5 rounded-full"
            style="width: 45%"
          ></div>
        </div>
      </div>
      <div class="flex text-2xl text-text-500">
        3
        <span class="text-lg text-text-500">min</span>
      </div>
    </div>
    <div class="flex justify-between gap-3">
      <div class="flex flex-col gap-2">
        <div class="text-text-100 text-2xl">Withdrawal</div>
        <div class="text-text-500 text-lg">The Avarage Arrival Time</div>
        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            class="bg-primary-550 h-2.5 rounded-full"
            style="width: 45%"
          ></div>
        </div>
        <div class="progress"></div>
      </div>
      <div class="flex h-full">
        <span class="flex h-full text-2xl items-center text-text-500">5</span>
        <span class="flex h-full text-lg items-center text-text-500">min</span>
      </div>
    </div>
  </div>
  <div class="flex flex-col pr-5 gap-3 w-full pl-2">
    <div class="flex gap-2">
      <img src="/icons/coin.svg" alt="coin" class="max-w-[64px]" />
      <div class="flex flex-col">
        <div class="text-text-100 text-2xl">Products</div>
        <div class="text-text-100 text-lg">Product Advantages</div>
      </div>
    </div>
    <div class="flex flex-col divide-y-[30%] divide-dashed">
      <div class="flex flex-col pr-2 pb-[5%]">
        <div class="text-text-100 text-2xl">SLOTS BETTING</div>
        <div class="text-text-500 text-lg">
          Online slots provider with variety selection of interesting games that
          make easy for players to hit the jackpot.
        </div>
      </div>
      <div class="flex flex-col pr-2 pb-[5%] pt-[5%]">
        <div class="text-text-100 text-2xl">LOTTERY BETTING</div>
        <div class="text-text-500 text-lg">
          An attractive lottery platform from the best companies in the world
          with the big winning prizes offers.
        </div>
      </div>
      <div class="flex flex-col pr-2 pb-[5%] pt-[5%]">
        <div class="text-text-100 text-2xl">LIVE CASINO BETTING</div>
        <div class="text-text-500 text-lg">
          The Platform of Choice for the World’s Top Companies, with the most
          games and variety..
        </div>
      </div>
      <div class="flex flex-col pr-2 pb-[5%] pt-[5%]">
        <div class="text-text-100 text-2xl">SPORT BETTING</div>
        <div class="text-text-500 text-lg">
          The Best Sportsbook Gaming Platform that offers more games, higher
          odds and provides more choices for players.
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col pr-5 gap-3 pl-2">
    <div class="flex gap-2">
      <img src="/icons/detail.svg" alt="detail" class="max-w-[64px]" />
      <div class="flex flex-col">
        <div class="text-text-100 text-2xl">HELP & SUPPORT</div>
        <div class="text-text-100 text-lg">MORE SERVICE</div>
      </div>
    </div>
    <div class="flex flex-col divide-y-[30%] divide-dashed">
      <div class="flex flex-col gap-4 pr-2 pb-[15%]">
        <div class="flex justify-between ml-auto">
          <div class="text-text-100 xl">OfficialOVO</div>
          <i></i>
        </div>
        <div class="flex justify-between ml-auto">
          <div class="text-text-100 xl">+62 813-2726-7080</div>
          <i></i>
        </div>
      </div>
      <div class="flex flex-col pr-2 pt-[15%] pb-[100%]">
        <div class="text-text-100 text-2xl">CONNECT WITH US</div>
        <div class="flex">
          <!-- <img /> -->
          <span class="text-text-100 text-xl">Social Media</span>
        </div>
      </div>
    </div>
  </div>
</div>
