import { Inject, inject, Injectable, Optional } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { of } from 'rxjs';
import { REQUEST } from '../app/express.token';
import { environment } from '../environments/environment';
import { ClientHttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  meta = inject(Meta);
  title = inject(Title);
  httpService = inject(ClientHttpService);
  constructor(@Optional() @Inject(REQUEST) private request: Request) {}

  setUpMetaTag() {
    // const url = this.request?.url;
    this.title.setTitle(environment.defaultTitle);
    return of({});
  }

  setTitle(title: string) {
    this.meta.updateTag({ name: 'title', content: title });
  }

  getSetting() {
    return this.httpService.get<{ data: { footerContent: string } }>(
      '/setting-svc/v1/websetting',
      true
    );
  }

  getAllSEOContent() {
    return this.httpService.get('/seo-content-management', true);
  }
}
