<p class="text-2xl font-bold text-special mt-8">Service Provider Platform</p>
<div class="flex flex-wrap gap-4 p-4">
  @if(providerImages()?.length){ @for(game of providerImages(); track
  game.title){ @if(game.logoViewUrls.length > 0){
  <div class="border-special relative mt-2">
    <div class="bg-primary-500 h-full">
      <div class="absolute top-0 left-4 -translate-y-1/2 bg-primary-500 p-1">
        {{ game.title }}
      </div>
      <div class="flex gap-2 p-2 flex-wrap">
        @for(image of game.logoViewUrls; track $index){
        <img
          [src]="image"
          alt="game"
          class="filter grayscale hover:grayscale-0"
        />
        }
      </div>
    </div>
  </div>
  } }}
</div>
