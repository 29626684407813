import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
@Component({
  standalone: true,
  selector: 'app-qr-banner',
  templateUrl: './banner-qr.component.html',
  imports: [CommonModule, ButtonComponent, NgOptimizedImage],
})
export class BannerQRComponent {}
