import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { StorageService } from '../services/storage.service';

export const isLogin: CanActivateFn = () => {
  const storageService = inject(StorageService);
  const cookiesService = inject(SsrCookieService);
  const platformId = inject(PLATFORM_ID);
  // // const response: any = inject('RESPONSE' as any);
  const router = inject(Router);

  if (isPlatformServer(platformId)) {
    if (cookiesService.check('sid')) {
      return false;
    }
    return false;
  }

  if (storageService.getSid()) {
    return true;
  }

  router.navigate(['/']);
  return false;
};
