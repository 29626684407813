<div class="bg-black/50 pt-4 pb-8 mt-4">
  <div class="max-w-[1200px] mx-auto">
    <div class="flex justify-between">
      <p class="ml-auto">{{ "COPYRIGHT" | translate }}</p>
    </div>
    <div class="footer-content"
      [innerHTML]="footerHtml()"></div>
    <app-qr-banner></app-qr-banner>
    <app-service-provider-platform />
    <app-site-info></app-site-info>
  </div>
</div>