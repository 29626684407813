import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { TooltipModule } from 'primeng/tooltip';
import { IconComponent } from '../../icon/icon.component';

@Component({
  standalone: true,
  selector: 'credit-balance',
  templateUrl: './credit-balance.component.html',
  imports: [TooltipModule, CommonModule, TranslatePipe, IconComponent],
})
export class CreditBalanceComponent {
  creditAmount = signal(90000);
  isLoading = signal(false);

  balanceItems = signal([
    {
      title: 'Hot Games',
      providers: [
        {
          providerName: 'Pragmatic Play',
          amount: 23.76,
        },
        {
          providerName: 'PG Soft',
          amount: 272.91,
        },
        {
          providerName: 'Hacksaw',
          amount: 108.78,
        },
      ],
    },
    {
      title: 'Slots',
      providers: [
        {
          providerName: 'Pragmatic Play',
          amount: 325.87,
        },
        {
          providerName: 'PG Soft',
          amount: 658.85,
        },
        {
          providerName: 'Hacksaw',
          amount: 802.92,
        },
        {
          providerName: 'Microgaming',
          amount: 584.3,
        },
        {
          providerName: 'Habanero',
          amount: 395.25,
        },
        {
          providerName: 'Nolimitcity',
          amount: 460.15,
        },
      ],
    },
    {
      title: 'Live Casino',
      providers: [
        {
          providerName: 'ICON Casino',
          amount: 311.18,
        },
        {
          providerName: 'PP Live Casino',
          amount: 500.05,
        },
        {
          providerName: 'MG Live Casino',
          amount: 24.75,
        },
        {
          providerName: 'Evogaming',
          amount: 223.52,
        },
      ],
    },
    {
      title: 'Lottery',
      providers: [
        {
          providerName: 'Balak4d',
          amount: 903.95,
        },
      ],
    },
    {
      title: 'Sports',
      providers: [
        {
          providerName: 'SBO',
          amount: 834.82,
        },
      ],
    },
    {
      title: 'Crash Game',
      providers: [
        {
          providerName: 'PP Live Casino',
          amount: 955.98,
        },
        {
          providerName: 'Spribe',
          amount: 572.85,
        },
        {
          providerName: 'Microgaming',
          amount: 518.52,
        },
        {
          providerName: 'Spinix',
          amount: 189.98,
        },
      ],
    },
    {
      title: 'Arcade',
      providers: [],
    },
    {
      title: 'Poker',
      providers: [],
    },
    {
      title: 'E-Sports',
      providers: [],
    },
    {
      title: 'Promotion',
      providers: [],
    },
  ]);

  reloadBalance() {
    this.isLoading.set(true);

    setTimeout(() => {
      this.creditAmount.set(100000);
      this.isLoading.set(false);
    }, 1000);
  }
}
