import { Injectable } from '@angular/core';

import { inject } from '@angular/core';
import { map } from 'rxjs';
import { MarketingPopupComponent } from '../components/marketing-popup/marketing-popup.component';
import { DialogService } from './dialog.service';
import { HomepageService } from './homepage.service';
import { StorageService, StorageType } from './storage.service';

@Injectable({ providedIn: 'root' })
export class MarketingPopupService {
  homepageService = inject(HomepageService);
  dialogService = inject(DialogService);
  storageService = inject(StorageService);

  private pages = [
    {
      path: '/',
      value: 'home',
    },
    {
      path: '/deposit',
      value: 'deposit',
    },
    {
      path: '/withdrawal',
      value: 'withdrawal',
    },
  ];

  showMarketingPopup(path: string) {
    const page = this.pages.find((p) => p.path === path)?.value;

    const currentPopupSate = JSON.parse(
      this.storageService.getItem(StorageType._marketingPopupState) || '{}'
    );

    if (
      page !== undefined &&
      currentPopupSate &&
      currentPopupSate[page] !== true
    ) {
      this.homepageService
        .getMetadata()
        .pipe(map((val) => val.popups))
        .subscribe(() => {
          // const popup = val.popups.find((p) => p.page === page);
          const popup = {
            title: 'Title No2',
            description: '<p>The description neww is here</p>',
            bannerFilePath:
              'popup-manager/vnca/Wed Sep 18 2024 16:52:50 GMT+0700 (Indochina Time)-Screenshot 2023-11-10 000716.png',
            bannerFileViewUrl: '',
            bannerWebpFilePath:
              'popup-manager/vnca/Wed Sep 18 2024 16:52:53 GMT+0700 (Indochina Time)-Screenshot 2023-11-11 000131.png',
            bannerWebpFileViewUrl: '',
            link: 'Link@linklinklink',
            isNewWindow: true,
            trigger: 0,
            page: 'withdrawal',
            isActive: true,
          };

          if (popup) {
            this.dialogService.openDialog(MarketingPopupComponent, {
              data: {
                popup,
              },
            });

            this.storageService.setItem(
              StorageType._marketingPopupState,
              JSON.stringify({ ...currentPopupSate, [page]: true })
            );
          }
        });
    }
  }
}
