<div
  [pTooltip]="tooltipContent"
  tooltipPosition="bottom"
  class="grid place-items-center h-full"
  tooltipStyleClass="[&_.p-tooltip-text]:!p-0 [&_.p-tooltip-text]:[background:unset] !pointer-events-auto"
  autoHide="false"
>
  <p
    class="flex gap-2 items-center border-x hover:cursor-pointer px-2"
    (click)="reloadBalance()"
    [tabindex]="1"
  >
    <core-icon
      path="/icons/reload.svg"
      class="!bg-primary-550 !w-4 !h-4"
      [ngClass]="{ 'animate-spin': isLoading() }"
    />
    <span class="text-primary-600 font-DigitalSans">{{
      creditAmount() | currency : "IDR " : "symbol" : "1.2-2"
    }}</span>
  </p>
  <ng-template #tooltipContent>
    <div class="border-special !rounded-lg min-w-[250px]">
      <div class="p-2 bg-black/85 flex flex-col max-h-[600px] overflow-y-auto">
        <div
          class="flex justify-between uppercase border-b pb-2 text-primary-200 font-semibold"
        >
          {{ "CREDIT_BALANCE" | translate }}
          <span class="font-DigitalSans">{{
            creditAmount() | currency : "IDR " : "" : "1.2-2"
          }}</span>
        </div>
        <div class="overflow-y-auto grow">
          @for(category of balanceItems(); track category.title) {
          <div class="flex flex-col">
            <span class="text-primary-200 uppercase mt-4">{{
              category.title
            }}</span>
            <div class="grid grid-cols-1 gap-[5px]">
              @for(provider of category.providers; track provider.providerName)
              {
              <div class="flex justify-between">
                <span class="ml-2">{{ provider.providerName }}</span>
                <span class="font-DigitalSans">{{
                  provider.amount | currency : "IDR " : "" : "1.2-2"
                }}</span>
              </div>
              }
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </ng-template>
</div>
