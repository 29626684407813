<p-toast
  styleClass="
[&_.p-toast-message-success]:bg-toast-success
[&_.p-toast-message-text]:text-white
[&_.p-toast-message-error]:bg-toast-error
[&_.p-toast-message-warning]:bg-toast-warning
[&_.p-toast-message-info]:bg-toast-info
[&_.p-toast-message-content]:p-2
[&_.p-toast-message-icon]:text-white
[&_.p-toast-icon-close]:text-white
"
/>
<div class="bg-primary-0 min-h-screen text-white flex flex-col">
  <router-outlet></router-outlet>
</div>
