import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { CategoryComponent } from '../home-page/category/category.component';
import { FooterComponent } from '../home-page/footer/footer.component';

@Component({
  standalone: true,
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    CategoryComponent,
    FooterComponent,
  ],
})
export class MainLayoutComponent {}
