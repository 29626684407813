import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'marketing-popup',
  standalone: true,
  imports: [CommonModule],
  template: ` {{ popup() | json }} `,
})
export class MarketingPopupComponent {
  popup = input();
}
